
















import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import StructureConfigurableMixin from '../../../../support/mixins/StructureConfigurable.mixin.vue'
import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { Inject, logger } from '../../../../support'
import { ShippingMethod } from '../../../../contexts'

import { AbstractStep } from '../AbstractStep'
import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'

import { ShippingStepConfig } from './ShippingStep.config'
import { SHIPPING_STEP_COMPONENT_KEY } from './ShippingStep.contracts'
import { Shippings } from '../Shippings'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ShippingStep>({
  name: 'ShippingStep',
  components: {
    Shippings
  }
})
export class ShippingStep extends Mixins(AbstractStep, CartAnalyticsMixin,
  StructureConfigurableMixin
) {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public selectedShipping: ShippingMethod | null = this.payload.shipping

  public callbacks: CheckoutStepCallback[] = []

  public config: ShippingStepConfig = this.getComponentConfig(
    SHIPPING_STEP_COMPONENT_KEY,
    { buttonGoNextTheme: 'secondary' }
  )

  public get isDisabled (): boolean {
    return !this.isValidStep()
  }

  public static isValidStep (payload: AnyObject): boolean {
    return !!payload.shipping
  }

  @Watch('selectedShipping')
  public selectShipping (value: string): void {
    this.onChange('shipping', value)
  }

  public async submit (): Promise<void> {
    if (!this.selectedShipping) {
      return
    }

    try {
      this.$emit('saving', true)
      let cart = await this.checkoutService.setShippingMethod(this.cart.id, this.selectedShipping)
      for (const c of this.callbacks) {
        const returned = await c.callable.apply(c.onThis, c.args)
        if (typeof returned === 'object' && returned !== null) {
          cart = returned
        }
      }

      this.$store.commit(CartMutationTypes.SetCart, cart)

      this.eventBus.emit('app:checkout.addShippingInfo', {
        ...this.getBaseCheckoutPayload(this.cart),
        shippingTier: this.cart.selectedShippingMethod?.methodCode
      })

      this.nextStep()
    } catch (e) {
      logger(e, 'warn')
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.$emit('saving', false)
    }
  }

  protected isValidStep (): boolean {
    return !!this.selectedShipping
  }
}

export default ShippingStep
