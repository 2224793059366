




















































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import { AddressData } from '../../../../contexts'

import { UserMixin } from '../../../profile/shared'
import { AuthMixin } from '../../../auth/shared'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<AddressSelector>({
  name: 'AddressSelector',
  mounted (): void {
    if (this.user) {
      if (!this.hasAddress) {
        return
      }

      this.updateSelectedAddressId()

      if (this.selectedAddress) {
        this.$emit('onMounted', {
          id: this.selectedAddress.id,
          address: this.selectedAddress
        })
      }
    }
  }
})
export class AddressSelector extends Mixins(UserMixin, AuthMixin) {
  @Prop({ type: Number, required: false, default: null })
  public selectedAddressId!: number | null

  public selectedId: number | null = null

  public get defaultAddress (): AddressData | null {
    return this.addresses.find((address) => {
      return address.defaultBilling
    }) ?? null
  }

  public get selectedAddress (): AddressData | null {
    return this.addresses.find((address) => {
      return address.id === this.selectedId
    }) ?? null
  }

  public setSelectedId (value: number | undefined) {
    const newAddressCandidate = this.addresses.find((address) => address.id === value)

    if (!newAddressCandidate) {
      return
    }

    this.$emit('onAddress', { id: newAddressCandidate.id, address: newAddressCandidate })
  }

  /**
   * Determines the list of addresses that user have.
   */
  public get addresses (): AddressData[] {
    if (!this.user) {
      return []
    }

    return this.user.addresses.map(address => {
      // strip dummy values
      return Object.entries(address).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value === '-' ? '' : value
      }), {} as AddressData)
    })
  }

  /**
   * Determines whether user has an address
   */
  public get hasAddress (): boolean {
    return this.addresses.length > 0
  }

  public get isSelectedInvalid (): boolean {
    if (!this.hasAddress || !this.selectedAddress) {
      return false
    }

    return this.isInvalid(this.selectedAddress)
  }

  public isInvalid (address: AddressData): boolean {
    const requiredFields: (keyof AddressData)[] = [
      'countryCode', 'city', 'firstName', 'lastName',
      'postalCode', 'phoneNumber', 'street'
    ]

    return requiredFields.some(field => !address[field])
  }

  /**
   * Emits @edit event with selected address.
   */
  public onEdit (id: number | undefined): void {
    const newAddressCandidate = this.addresses.find((address) => address.id === id)

    if (!newAddressCandidate) {
      return
    }

    this.$emit('edit', { id: newAddressCandidate.id, address: newAddressCandidate })
  }

  /**
   * Determines whether given address is for company
   */
  public isCompany (address: AddressData): boolean {
    return !!address.vatId && address.vatId.length > 0
  }

  @Watch('selectedAddressId')
  private updateSelectedAddressId (): void {
    this.selectedId = this.selectedAddressId ?? this.defaultAddress?.id ?? null
  }
}

export default AddressSelector
